'use client';
import clsx from 'clsx';
import { useClientTranslation } from '@/hook/translate';
import { useActivePath } from '@/hook';
import { useCallback } from 'react';
import { useParams } from 'next/navigation';
import { useRouter } from 'next/navigation';
import { ListenBriefImg } from '@/config/img/listenbiref';
import LanguageChange from './components/LanguageChange';
// import { Image } from '@nextui-org/react';
import { dealLng } from '@/utils';
import Link from 'next/link';
import Image from 'next/image';


export default function BigHeader() {
  const { t } = useClientTranslation();
  const activePath = useActivePath(['bookmagazine', 'collection', 'pdf']);
  const params = useParams();

  const router = useRouter();
  const imageLoader = useCallback(({ src }: { src: string }) => {
    return src;
  }, []);
  const goBookMagazine = useCallback(() => {
    let lng = dealLng(params.lng as string || '');
    router.push(lng + '/bookmagazine/list/1/');
  }, [params, router]);


  const goCollection = useCallback(() => {
    let lng = dealLng(params.lng as string || '');
    console.log(lng);
    router.push(lng + '/collection/list/1/');
  }, [params, router]);
  return (
    <div className='h-20 flex items-center px-[150px] bg-black'>
      <Link
        href={`${dealLng(params.lng as string) || '/'}`}
      >
        <Image
          priority
          loader={imageLoader}
          src={ListenBriefImg.ListenbriefLogo}
          alt='logo'
          width={170}
          height={35}
          className='cursor-pointer'
        />
      </Link>
      <div className='flex-1 flex justify-end'>
        <p
          className={
            clsx('mr-10 font-semibold text-base cursor-pointer hidden',
              activePath[0] ? 'text-[#3AD1FF]' : 'text-white',
            )
          }
          onClick={goBookMagazine}
        >
          {t('bookMagazine', 'listenBriefs')}
        </p>
        <p className={
          clsx('mr-10 font-semibold text-base cursor-pointer hidden',
            activePath[1] ? 'text-[#3AD1FF]' : 'text-white',
          )
        } onClick={goCollection}>
          {t('collection', 'listenBriefs')}
        </p>
        <p
          className={
            clsx(' mr-10 font-semibold text-base cursor-pointer',
              activePath[2] ? 'text-[#3AD1FF]' : 'text-white',
            )
          }
        >
          <Link href={`${dealLng(params.lng as string)}/pdf/1`}>PDF</Link>
        </p>
        <LanguageChange />
      </div>
    </div>
  );
}
