export enum SharePlatform {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Linkedin = 'Linkedin',
  Weibo = 'Weibo',
  QQ = 'QQ',
  Wechat = 'Wechat',
  Email = 'Email',
  Copy = 'Copy',
  Link = 'Link',
}


export interface ShareLinkOptions {
  url: string;
  title?: string;
  description?: string;
}

export type TPdfItem = {
  _id: string;
  title: string;
  pdfPath: string;
}

export interface IBaseRes<T> {
  code: number;
  message: string;
  data: T
}
