
import { usePathname } from 'next/navigation';
import {
  tranInstanceManager,
} from './translate';

/**
 * useActivePath 判断指定路由是否在当前路由
 */
function useActivePath(paths: string[]) {
  const allPath = usePathname();

  return paths.map(path => allPath.includes(path));
}

export {
  tranInstanceManager,
  useActivePath,
};
