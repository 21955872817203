const prefix: string = process.env.NEXT_PUBLIC_LISTEN_BRIEF_IMG_ADDRESS || '';
export const ListenBriefImg =  {
  DownCode : prefix + '/code.png',
  AddWeekly : prefix + '/add-weekly.png',
  AppStore : prefix + '/app-store.png',
  BookeyLogo : prefix + '/bookey-logo.png',
  Code : prefix + '/code.png',
  DownArrow : prefix + '/down-arrow.png',
  FooterBg : prefix + '/footer-bg.png',
  FooterLogo : prefix + '/footer-logo.png',
  GooglePlayBadge : prefix + '/google-play-badge.png',
  Insight : prefix + '/insight.png',
  LeftArrow : prefix + '/left-arrow.png',
  ListenbriefLogo : prefix + '/listenbrief-logo.png',
  Lock : prefix + '/lock.png',
  MobileList : prefix + '/mobile-list.png',
  PlayAudio : prefix + '/play-audio.png',
  StopAudio : prefix + '/stop-audio.png',
  RightArrow :  prefix + '/right-arrow.png',
  Star :  prefix + '/star.png',
  TestBook :  prefix + '/test-book.png',
  Test : prefix + '/test.png',
  TopImage : prefix + '/top-image.png',
  Topic : prefix + '/topic.png',
  Triangle : prefix + '/Triangle.png',
  ArrowUp: prefix + '/arrow-up.png',
  arrowDown: prefix + '/arrow-down.png',
  audioPlay: prefix + '/audio-play.png',
  audioStop: prefix + '/audio-stop.png',
  ReaderDown: prefix + '/reader-down.png',
  ReaderLeft: prefix + '/reader-left.png',
  ReaderRight: prefix + '/reader-right.png',
};
