import { locales } from '@/config/http/base';
import { ShareLinkOptions, SharePlatform } from '@/types';

function throttle<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
  let isThrottled = false;
  return function (this: any, ...args: Parameters<T>) {
    const context = this;
    if (!isThrottled) {
      isThrottled = true;
      func.apply(context, args);
      setTimeout(() => {
        isThrottled = false;
      }, delay);
    }
  };
}

function formatSecondsTime(seconds: number) {
  let minutes: number | string = Math.floor(seconds / 60);
  let remainingSeconds: number | string = seconds % 60;

  // 添加前导零
  minutes = String(minutes).padStart(2, '0');
  remainingSeconds = String(remainingSeconds).padStart(2, '0');

  return `${minutes}:${remainingSeconds}`;
}


function formatSimpleDate(isoString: string, split: string) {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${split}${month}${split}${day}`;
}

/** 将秒转换为 mm:ss 或者 hh:mm:ss */
function formatSecondsToYoutube(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let timeString = '';
  if (hours > 0) {
    timeString += hours.toString().padStart(2, '0') + ':';
  }
  timeString += minutes.toString().padStart(2, '0') + ':';
  timeString += remainingSeconds.toString().padStart(2, '0');

  return timeString;
}

const generateShareLink = (platform: SharePlatform, options: ShareLinkOptions) => {
  const { url, title, description } = options;
  let shareUrl: string;

  switch (platform) {
    case SharePlatform.Twitter:
      shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title || '')}`;
      break;
    case SharePlatform.Facebook:
      shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
      break;
    case SharePlatform.Linkedin:
      shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title || '')}&summary=${encodeURIComponent(description || '')}`;
      break;
    case SharePlatform.Link:
    default:
      shareUrl = url;
      break;
  }
  return shareUrl;

};



const dealLng = (lng: string) => {
  if (locales.includes(lng)) {
    if (lng === 'en') {
      return  '';
    } else {
      return `/${lng}` ;
    }
  } else {
    return '';
  }
};

// 通过环境变量获取当前配置的域名
function getDomainConfig() {
  const envVar = process.env.NEXT_DOMAIN_LIST || '';
  let obj: { [key: string]: string } = {};
  envVar.split('|').forEach(item => {
    const [domain, startPath] = item.split(':');
    obj[domain] = startPath;
  });
  return obj;
}


// 处理目录路由
function dealPath(path?: string) {
  return encodeURIComponent(path?.replace(/\s+/g, '-') || '');
}


const imageLoader = ({ src }: { src: string } ) => {
  return src;
};

// 处理URL中带不能转义的特殊符号  A-Z a-z 0-9 - _ . ! ~ * ' ( )
function dealUrl(url: string) {
  return url.replace(/&apos;/g, "%27").replace(/'/g, '%27');
}

export {
  throttle,
  formatSecondsTime,
  formatSimpleDate,
  generateShareLink,
  formatSecondsToYoutube,
  dealLng,
  getDomainConfig,
  dealPath,
  imageLoader,
  dealUrl,
};

