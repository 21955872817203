// 请求超时时间
const timeout = 60 * 1000;

enum HTTPLocales {
  // zh  = 'zh',
  en = 'en',
  fr = 'fr',
  es = 'es',
  de = 'de',
  pt = 'pt',
}

const locales = ['en', 'es', 'fr', 'de', 'pt'];


export {
  timeout,
  HTTPLocales,
  locales,
};
